import { CONTENT_STYLES } from '@/constants/typography'

export const INPUT_COMMON_PROPS = {
  ...CONTENT_STYLES,
  isRequired: true,
  type: 'text',
  variant: 'outline',
  colorScheme: 'blackAlpha',
  backgroundColor: 'bgSecondary',
  color: 'mainColorText',
  borderTopLeftRadius: '8px',
  borderBottomLeftRadius: '8px',
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px',
  borderColor: 'transparent',
  _placeholder: { color: 'blackAlpha.500' },
  _focus: { borderColor: 'mainColorText' },
};

export const PHONE_SELECT_COMMON_PROPS = {
  ...CONTENT_STYLES,
  borderRadius: '0',
  variant: 'outline',
  backgroundColor: 'bgSecondary',
  color: 'mainColorText',
  borderTopLeftRadius: '8px',
  borderBottomLeftRadius: '8px',
  borderTopColor: 'bgSecondary',
  borderRight: '1px solid #fff',
  borderBottomColor: 'bgSecondary',
  borderLeftColor: 'bgSecondary',
  _focus: { borderColor: 'mainColorText' },
};

export const SELECT_COMMON_PROPS = {
  ...CONTENT_STYLES,
  borderRadius: '8px',
  variant: 'outline',
  backgroundColor: 'bgSecondary',
  color: 'mainColorText',
  borderColor: 'transparent',
  _focus: { borderColor: 'transparent' },
};

export const FORM_LABEL_PROPS = {
  ...CONTENT_STYLES,
  margin: '24px 0 8px 0',
};

export const ERROR_PROPS = {
  color: 'red.400',
  marginTop: '4px',
};

export const TYPE = {
  YES: 'YA',
  NO: 'TIDAK',
};

export const ERROR_TYPE = {
  name: undefined,
  email: undefined,
  phone: undefined,
  address: undefined,
};

export const SELECT_PHONE_PROPS = {
  borderRadius: '0',
  size: 'sm',
  variant: 'outline',
  backgroundColor: '#C1CDB7',
  color: 'mainColorText',
  fontFamily: 'body',
  borderColor: 'transparent',
  appearance: 'none',
  cursor: 'pointer',
  _focus: { borderColor: 'transparent' },
};